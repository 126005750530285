<template>
  <v-form>
    <v-container>
      <div class="centerDiv" v-if="forgetPassword">
        <v-card class="mx-auto pa-7 mt-10" max-width="500">
          <h1 class="text-h4 font-weight-thin text-center mb-4">
            Golegit Backoffice
          </h1>
          <h1 class="text-h5 font-weight-thin mb-4">Login</h1>
          <v-row>
            <v-col cols="12" sm="12">
              <VuePhoneNumberInput
                default-country-code="NG"
                v-model="unformatedphoneNumber"
                @update="results = $event"
                filled
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="password"
                label="Enter Password"
                filled
                shaped
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn @click="login()" rounded color="info" dark> Continue </v-btn>
          </div>
          <div class="text-center mt-4">
            <v-btn
              small
              @click="
                forgetPassword = false;
                password = '';
              "
              rounded
              color="warning"
              text
              dark
            >
              Forgot Password
            </v-btn>
          </div>
        </v-card>
      </div>
      <div class="centerDiv" v-else>
        <v-card class="mx-auto pa-7 mt-10" max-width="500">
          <h1 class="text-h4 font-weight-thin text-center mb-4">
            Golegit Backoffice
          </h1>
          <h1 class="text-h5 font-weight-thin mb-4">Forgot Password</h1>
          <v-row>
            <v-col cols="12" sm="12">
              <VuePhoneNumberInput
                default-country-code="NG"
                v-model="unformatedphoneNumber"
                @update="results = $event"
                filled
              />
            </v-col>
            <v-col cols="12" sm="12" v-if="codeSent">
              <v-text-field
                v-model="activationCode"
                label="Enter OTP CODE"
                filled
                shaped
                type="number"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Enter New Password"
                filled
                shaped
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="text-center ma-4">
            <v-btn
              v-if="codeSent"
              @click="confirmRestPassword()"
              rounded
              color="pink"
              dark
            >
              Submit
            </v-btn>
            <v-btn v-else @click="restPassword()" rounded color="pink" dark>
              Rest Password
            </v-btn>
          </div>
          <div class="text-center mt-4">
            <v-btn
              small
              @click="
                forgetPassword = true;
                codeSent = false;
                password = '';
              "
              rounded
              color="info"
              text
              dark
            >
              Back</v-btn
            >
          </div>
        </v-card>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import axios from "axios";
import { ConfirmRestPassword, RestPassword } from "../APIs";
export default {
  components: {
    VuePhoneNumberInput,
  },
  data: () => ({
    forgetPassword: true,
    password: "",
    phoneNumber: "",
    unformatedphoneNumber: "",
    results: null,
    codeSent: false,
    activationCode: "",
  }),
  methods: {
    login() {
      if (this.password == "" || this.phoneNumber == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        route: this.$router,
        phoneNumber: this.phoneNumber,
        password: this.password,
        redirect: this.$route.query.redirect,
      };
      this.$store.dispatch("Login", payload);
    },
    restPassword() {
      this.$store.commit("setLoading", true);
      axios
        .put(
          `${RestPassword}`,
          {
            phoneNumber: this.phoneNumber,
          },
          {
            headers: { Authorization: "Bearer " + this.getToken },
          }
        )
        .then((res) => {
          this.codeSent = true;
          this.$store.commit("setSnackbar", true);
          this.$store.commit("setServerMessage", res.data.message);
        })
        .catch((err) => {
          this.$store.commit("setSnackbar", true);
          this.$store.commit("setServerMessage", err.response.data.message);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
    confirmRestPassword() {
      this.$store.commit("setLoading", true);
      axios
        .put(
          `${ConfirmRestPassword}`,
          {
            phoneNumber: this.phoneNumber,
            activationCode: this.activationCode,
            password: this.password,
          },
          {
            headers: { Authorization: "Bearer " + this.getToken },
          }
        )
        .then((res) => {
          this.codeSent = false;
          this.password = "";
          this.activationCode = "";
          this.forgetPassword = true;
          this.$store.commit("setSnackbar", true);
          this.$store.commit("setServerMessage", res.data.message);
        })
        .catch((err) => {
          this.$store.commit("setSnackbar", true);
          this.$store.commit("setServerMessage", err.response.data.message);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
      this.codeSent = true;
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    results(val) {
      if (val) {
        if (val.formattedNumber) {
          this.phoneNumber = val.formattedNumber;
        }
      }
    },
  },
};
</script>

<style scoped>
.centerDiv {
  height: 95vh;
  padding-top: 20vh;
}
</style>